import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero"

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Hero pageTitle="Privacy Policy" />
      <section className="section-1 highlights image-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h5>What Are Cookies</h5>

              <p>
                As is common practice with almost all professional websites this
                site uses cookies, which are tiny files that are downloaded to
                your computer, to improve your experience. This page describes
                what information they gather, how we use it and why we sometimes
                need to store these cookies. We will also share how you can
                prevent these cookies from being stored however this may
                downgrade or ‘break’ certain elements of the sites
                functionality.
              </p>
              <h5>How We Use Cookies</h5>
              <p>
                We use cookies for a variety of reasons detailed below.
                Unfortunately, in most cases there are no industry standard
                options for disabling cookies without completely disabling the
                functionality and features they add to this site. It is
                recommended that you leave on all cookies if you are not sure
                whether you need them or not in case they are used to provide a
                service that you use.
              </p>

              <h5>Disabling Cookies</h5>
              <p>
                You can prevent the setting of cookies by adjusting the settings
                on your browser (see your browser Help for how to do this). Be
                aware that disabling cookies will affect the functionality of
                this and many other websites that you visit. Disabling cookies
                will usually result in also disabling certain functionality and
                features of this website. Therefore, it is recommended that you
                do not disable cookies.
              </p>
              <h5>Third Party Cookies</h5>
              <p>
                In some special cases we also use cookies provided by trusted
                third parties. The following section details which third party
                cookies you might encounter through this site.
              </p>
              <p>
                This site uses Google Analytics which is one of the most
                widespread and trusted analytics solution on the web for helping
                us to understand how you use the site and ways that we can
                improve your experience. These cookies may track things such as
                how long you spend on the site and the pages that you visit so
                we can continue to produce engaging content.
              </p>
              <p>
                For more information on Google Analytics cookies, see the
                official Google Analytics page.
              </p>
              <h5>More Information</h5>
              <p>
                Hopefully this has clarified things for you and as was
                previously mentioned if there is something that you aren’t sure
                whether you need or not it’s usually safer to leave cookies
                enabled in case it does interact with one of the features you
                use on our site. However if you are still looking for more
                information then you can contact us through one of our preferred{" "}
                <Link to="/contact">contact methods.</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicyPage
